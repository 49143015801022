const transactionsMap = {
  merchantRefNumber: {
    displayName: "Merchant Reference Id",
    path: "merchantReferenceId",
    order: 1,
    filtered: true,
    filterOptions: {
      order: 2,
      type: "STRING",
      queryParam: "merchantReferenceId",
      selectedOptions: [],
    },
  },

  id: {
    displayName: "Id",
    path: "id",
    order: 2,
    filtered: false,
    filterOptions: {
      order: 3,
      type: "STRING",
      queryParam: "id",
      selectedOptions: [],
    },
  },
  status: {
    displayName: "Status",
    path: "status",
    order: 3,
    filtered: true,
    filterOptions: {
      order: 3,
      type: "ENUM",
      options: [
        "COMPLETED",
        "PROCESSING",
        "PENDING",
        "FAILED",
        "ERROR",
        "EXPIRED",
        "INITATED",
        "HELD",
      ],
      queryParam: "status",
      selectedOptions: [],
    },
  },
  paymentType: {
    displayName: "Payment Type",
    path: "paymentMethod",
    order: 4,
    filtered: true,
    filterOptions: {
      order: 3,
      type: "ENUM",
      options: [
        "BANK_TRANSFER",
        "CARDS",
        "QRIS",
        "OVO",
        "GO_PAY",
        "DANA",
        "DOKU",
        "ALFAMART",
        "INDOMARET",
        "MANDIRI_CLICKPAY",
        "ALIPAY",
        "SHOPEEPAY",
        "SAKUKU",
        "NEXCASH",
        "AKULAKU",
        "KREDIVO",
        "LINKAJA",
        "ASTRAPAY",
        "JENIUSPAY",
        "UANGME",
        "INDODANA",
        "ATOME",
      ],
      queryParam: "paymentMethod",
      selectedOptions: [],
    },
  },
  processorCode: {
    displayName: "Processor Code",
    path: "processorCode",
    order: 5,
    filtered: true,
    filterOptions: {
      order: 3,
      type: "ENUM",
      options: ["XENDIT", "MIDTRANS", "IPAYMU", "DOKU", "ADYEN", "STRIPE", "CHECKOUT", "MYFATOORAH", "TAP", "MOYASAR", "HYPERPAY", "STCPAY", "PAYTABS", "CYBERSOURCE", "PAYSCAPE", "PAYKINGS", "PAYLINE", "CXPAY", "SKY_BANK", "T1PAYMENTS", "DURANGO", "FORTIS", "NP_BANK", "DHARMA", "VIKING", "NWIN_BANK", "NBOK_BANK_MPGS", "SUNCORP_BANK", "BSFR_BANK", "DOHA_BANK", "ANZB_BANK", "BOA_BANK", "UNION_BANK", "KCB_BANK","ADCB_BANK","AFS_BANK","AREEBA_BANK","AXIS_BANK","ALFALAH_BANK","AUDI_BANK","ADIB_BANK","BABE_BANK","BLFS_BANK","BEND_BANK","BRGN_BANK","CCEY_BANK","CREDIMAX","HSBC_BANK","KFHO_BANK","GLOBALPMT","MSHQ_BANK","MCBL_BANK","MCB_EGATE","NBEG_BANK","NBOM_BANK"],
      queryParam: "processorCode",
      selectedOptions: [],
    },
  },
  amount: {
    displayName: "Amount",
    path: "amount.value",
    order: 4,
    filtered: true,
    filterOptions: {
      order: 1,
      type: "INTEGER",
      queryParamInbound: "amount_to",
      queryParamOutbound: "amount_from",
      selectedOptions: [],
    },
  },

  currencyCode: {
    displayName: "Currency Code",
    path: "amount.currencyCode",
    order: 4,
    filtered: true,
    filterOptions: {
      order: 4,
      type: "ENUM",
      options: ["IDR", "USD"],
      queryParam: "currency",
      selectedOptions: [],
    },
  },
  date: {
    displayName: "Date",
    path: "transactionTime,createdAt",
    order: 9,
    filtered: true,
    filterOptions: {
      order: 8,
      type: "DATE",
      queryParamInbound: "transactionTime_to",
      queryParamOutbound: "transactionTime_from",
      selectedOptions: [],
    },
  },
  processorReconId: {
    displayName: "Processor Recon Id",
    path: "processorReconId",
    order: 10,
    filtered: true,
    filterOptions: {
      order: 10,
      type: "STRING",
      queryParam: "processorReconId",
    },
  },
  processorId: {
    displayName: "Processor Id",
    path: "processorTransactionId",
    order: 11,
    filtered: true,
    filterOptions: {
      order: 12,
      type: "STRING",
      queryParam: "processorTransactionId",
    },
  },
  transactionType: {
    displayName: "Transaction Type",
    path: "transactionType",
    order: 1,
    filtered: true,
    filterOptions: {
      order: 1,
      type: "ENUM",
      queryParam: "transactionType",
      options: ["PAYMENT", "CAPTURE", "REFUND", "CREDIT", "CHECKOUT_SESSION"],
    },
  },
};
const ListOfShowingColumns = [
  "merchantRefNumber",
  "id",
  "transactionType",
  "status",
  "paymentType",
  "processorCode",
  "amount",
  "currencyCode",
  "date",
  "processorReconId",
  "processorId",
];
ListOfShowingColumns.sort(
  (a, b) => transactionsMap[a].order - transactionsMap[b].order
);
function getColumnValue(item, path) {
  const paths = path.split(".");
  let value = item;
  for (const path of paths) {
    value = value[path];
    if (value === undefined) return null; // return empty string if any intermediate path is undefined
  }
  return value;
}
export default { transactionsMap, ListOfShowingColumns, getColumnValue };
