// src/i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './components/locales/en.json';
import frTranslations from './components/locales/fr.json';

i18n
  .use(initReactI18next) // Pass i18n instance to react-i18next
  .init({
    resources: {
      en: { translation: enTranslations },
      fr: { translation: frTranslations },
    },
    fallbackLng: 'en', // Default language if the detected language is not available
    debug: true, // Set to false in production
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18n;
