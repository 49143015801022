import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { analyticsData } from "./Dummydata";

// Import necessary Chart.js components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the components needed
ChartJS.register(
  CategoryScale, // For the x-axis category scale
  LinearScale, // For the y-axis linear scale
  BarElement, // For bar charts
  PointElement,
  Title, // For chart title
  Tooltip, // For tooltips
  Legend // For the chart legend
);

const TransactionCountChart = ({ analyticsData, type, text }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    // Aggregate data based on the type (paymentMethod or processorCode)
    const aggregatedData = {};

    // Aggregate transaction counts per paymentMethod or processorCode
    analyticsData.forEach((item) => {
      const key = item[type];
      if (aggregatedData[key]) {
        aggregatedData[key] += item.transactionCount;
      } else {
        aggregatedData[key] = item.transactionCount;
      }
    });

    // Prepare the chart data
    const labels = Object.keys(aggregatedData);
    const transactionCounts = Object.values(aggregatedData);

    setChartData({
      labels,
      datasets: [
        {
          label: "Transaction Count",
          data: transactionCounts,
          backgroundColor: "rgba(54, 162, 235, 0.6)",
        },
      ],
    });
  }, [analyticsData, type]);

  if (!chartData) return <p>Loading...</p>;

  const options = {
    responsive: true,
    indexAxis: "y", // Keeps horizontal bars (indexAxis is 'y' for horizontal)
    scales: {
      x: {
        type: "linear", // x-axis is a linear scale for the transaction count
        title: {
          display: true,
          // text: "Transaction Count", // Label for the x-axis
        },
      },
      y: {
        type: "category", // y-axis is a category type for labels (paymentMethod or processorCode)
        title: {
          display: true,
          text: text, // Label for the y-axis (paymentMethod or processorCode)
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Transaction Count by ${text}`,
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default TransactionCountChart;
