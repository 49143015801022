import React from "react";

const SelectedRaio = () => (
  <svg
    xmlns="https://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
  >
    <path
      d="M2 10.4114C2 5.9931 5.58172 2.41138 10 2.41138C14.4183 2.41138 18 5.9931 18 10.4114C18 14.8297 14.4183 18.4114 10 18.4114C5.58172 18.4114 2 14.8297 2 10.4114Z"
      fill="#1b84ff"
    />
    <path
      d="M6 10.4114C6 8.20224 7.79086 6.41138 10 6.41138C12.2091 6.41138 14 8.20224 14 10.4114C14 12.6205 12.2091 14.4114 10 14.4114C7.79086 14.4114 6 12.6205 6 10.4114Z"
      fill="white"
    />
  </svg>
);

export default SelectedRaio;
