import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

// Import necessary Chart.js components
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the components needed
ChartJS.register(
  CategoryScale, // For the x-axis category scale
  LinearScale, // For the y-axis linear scale
  BarElement, // For bar charts
  PointElement,
  Title, // For chart title
  Tooltip, // For tooltips
  Legend // For the chart legend
);

const TotalAmountChart = ({ analyticsData, type, text }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    // Group data by the given 'type' and sum the totalAmount for each group
    const groupedData = analyticsData.reduce((acc, item) => {
      const groupKey = item[type] || "Unknown"; // Use 'Unknown' for missing or undefined type
      if (!acc[groupKey]) {
        acc[groupKey] = 0;
      }
      acc[groupKey] += item.totalAmount; // Add the totalAmount to the corresponding group
      return acc;
    }, {});

    // Prepare labels and data for the chart
    const labels = Object.keys(groupedData); // Use the grouped keys (e.g., paymentMethod or processorCode)
    const totalAmounts = Object.values(groupedData); // Get the corresponding total amounts for each group

    setChartData({
      labels,
      datasets: [
        {
          label: "Total Amount",
          data: totalAmounts,
          backgroundColor: "rgba(54, 162, 235, 0.6)", // Color for bars
        },
      ],
    });
  }, [analyticsData, type]); // Re-run effect if analyticsData or type changes

  if (!chartData) return <p>Loading...</p>;

  const options = {
    responsive: true,
    scales: {
      x: {
        type: "category", // Set x-axis to category type
        title: {
          display: true,
          text: text, // Label for the x-axis
        },
      },
      y: {
        type: "linear", // Set y-axis to linear scale
        title: {
          display: true,
          text: "Total Amount", // Label for the y-axis
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Total Amount per ${text}`,
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default TotalAmountChart;
