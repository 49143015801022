import Rules from "components/Rules/Rules";
import Transactions from "components/Transactions/Transactions";
import Developers from "components/Developers/Developers";
import Logout from "../Logout";
import HomeSettings from "../components/Home/Settings";
import TransactionsIcon from "components/Icons/Transactions";
// import { Devlopers } from "components/Icons/Developers";
import { DevelopersIcon } from "components/Icons/Developers";
import { useTranslation } from "react-i18next";
import RulesIcon from "components/Icons/Rules";
import HomeIcon from "components/Icons/HomeLine";
import SignOut from "components/Icons/SignOut";
import { DashboardIcon } from "components/Icons/Dashboard";
import Dashboard from "components/Dashboard/Dashboard";
import { ProcessorIcon } from "components/Icons/Processor";

export const MenuItems = () => {
  const { t } = useTranslation();

  return [
    {
      name: t("DASHBOARD"),
      icon: <DashboardIcon />,
      label: "Dashboard",
      component: <Dashboard />,
    },
    {
      name: t("PROCESSOR"),
      icon: <ProcessorIcon />,
      label: "Processor",
      component: <HomeSettings />,
    },
    {
      name: t("RULES"),
      icon: <RulesIcon />,
      label: "Rules",
      component: <Rules />,
    },
    {
      name: "Transactions",
      icon: <TransactionsIcon />,
      label: "Transactions",
      component: <Transactions />,
    },

    {
      name: t("DEVELOPERS"),
      icon: <DevelopersIcon />,
      label: "Developers",
      component: <Developers />,
    },

    {
      name: t("LOGOUT"),
      icon: <SignOut />,
      label: "Logout",
      component: <Logout />,
    },
  ];
};
