import * as React from "react";
import "./Breadcrumb.css";
import { useTranslation } from "react-i18next";

const Breadcrumb = ({ list, onClick }) => {
  const { t, i18n } = useTranslation();

  // Handle language change from the dropdown
  const handleLanguageChange = (event) => {
    const lang = event.target.value;
    i18n.changeLanguage(lang);
  };
  return list.map((item) => (
    <div className="containers">
      <div className="breadcrumb-item">
        {item.src && (
          <img
            loading="lazy"
            src={item.src}
            className="breadcrumb-img"
            onClick={onClick}
          />
        )}
        {item.component && (
          <div className="breadcrumb-component" onClick={onClick}>
            {item.component}
          </div>
        )}
        <div className="breadcrumb-title" onClick={onClick}>
          {item.title}
        </div>
      </div>
      <div className="d-flex align-items-center language-select-wrapper">
        <label htmlFor="language-select" className="me-2">
          {t("LANGUAGE")}:
        </label>
        <select
          className=""
          id="language-select"
          onChange={handleLanguageChange}
          defaultValue={i18n.language} // Ensure the current language is selected in the dropdown
        >
          <option value="en">English</option>
          <option value="fr">Français</option>
          {/* Add more languages here */}
        </select>
      </div>
    </div>
  ));
};

export default Breadcrumb;
