const processorIcons = {
  MIDTRANS: "logos/midtrans.png",
  XENDIT: "logos/xendit-logo.jpg",
  DOKU: "logos/doku-logo.jpg",
  IPAYMU: "logos/ipaymu-logo.png",
  STRIPE: "logos/STRIPE.svg",
  ADYEN: "logos/ADYEN.svg",
  AUTHORIZE_NET: "logos/AUTHORIZEDOTNET.svg",
  BLUESNAP: "logos/BLUESNAP.svg",
  BRAINTREE: "logos/BRAINTREE.svg",
  CHECKOUT: "logos/CHECKOUT.svg",
  FORTE: "logos/FORTE.svg",
  CYBERSOURCE: "logos/CYBERSOURCE.svg",
  PAYPAL: "logos/PAYPAL.svg",
  STAX: "logos/STAX.svg",
  RAPYD: "logos/RAPYD.svg",
  TAP: "logos/TAP.svg",
  MYFATOORAH: "logos/MYFATOORAH.svg",
  MOYASAR: "logos/MOYASAR.svg",
  STCPAY: "logos/STCPAY.svg",
  HYPERPAY: "logos/HYPERPAY.svg",
  PAYTABS: "logos/PAYTABS.svg",
  CYBERSOURCE: "logos/CYBERSOURCE.svg"
};

export default processorIcons;
