// httpClient.js
import Logout from "Logout";
class HttpClient {
  constructor() {
    this.defaults = {
      headers: {},
    };
  }

  setHeader(name, value) {
    this.defaults.headers[name] = value;
  }

  getHeader(name) {
    return this.defaults.headers[name];
  }

  removeHeader(name) {
    delete this.defaults.headers[name];
  }

  async fetch(url, options = {}) {
    const requestOptions = {
      ...this.defaults,
      ...options,
      headers: {
        ...this.defaults.headers,
        ...options.headers,
      },
    };

    const response = await fetch(url, requestOptions);
    // You can add error handling or other logic here if needed
    return response;
  }

  async fetchData(url, method, body) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(url, {
          method: method,
          ...(body && { body: JSON.stringify(body) }),
          headers: {
            ...this.defaults.headers,
            "Content-Type": "application/json",
          },
        });
        if (response.status === 401) {
          Logout();
        } else if (response.status === 202) {
          // Handle the case of HTTP 202 (Accepted) without a body
          resolve(null); // Or you can resolve with any specific value
        } else {
          const data = await response.json();
          resolve(data);
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  async postFormData(url, formData) {
    console.log(formData);
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(url, {
          method: "POST",
          body: formData.toString(),
          headers: {
            ...this.defaults.headers,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });

        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }
}

const httpClient = new HttpClient();

export { httpClient };
