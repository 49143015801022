import React from "react";

const Synergy = () => (
  <svg
    xmlns="https://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="0 0 32 32"
    fill="none"
  >
    <rect width="32" height="32" rx="8" fill="#656BFD" />
    <path
      d="M19.222 4.21152L16.7665 3.55579L14.6971 11.2524L12.8289 4.30385L10.3733 4.95958L12.3918 12.467L7.36421 7.45648L5.5666 9.24798L11.0813 14.7439L4.21363 12.91L3.55566 15.3572L11.0594 17.361C10.9735 16.9917 10.928 16.6069 10.928 16.2116C10.928 13.4131 13.2044 11.1444 16.0124 11.1444C18.8205 11.1444 21.0968 13.4131 21.0968 16.2116C21.0968 16.6044 21.052 16.9868 20.9671 17.3539L27.7866 19.175L28.4446 16.7278L20.911 14.716L27.7791 12.8819L27.1211 10.4347L19.5878 12.4464L24.6154 7.43591L22.8178 5.64442L17.3796 11.0641L19.222 4.21152Z"
      fill="url(#paint0_linear_258_4435)"
      fill-opacity="0.88"
    />
    <path
      d="M20.9605 17.3826C20.7499 18.2696 20.3055 19.0665 19.6956 19.705L24.636 24.6287L26.4336 22.8372L20.9605 17.3826Z"
      fill="url(#paint1_linear_258_4435)"
      fill-opacity="0.88"
    />
    <path
      d="M19.6458 19.7563C19.0285 20.3846 18.2485 20.8534 17.3738 21.095L19.1716 27.7814L21.6271 27.1257L19.6458 19.7563Z"
      fill="url(#paint2_linear_258_4435)"
      fill-opacity="0.88"
    />
    <path
      d="M17.2821 21.1195C16.8763 21.2235 16.4509 21.2787 16.0126 21.2787C15.543 21.2787 15.0882 21.2153 14.6565 21.0965L12.8571 27.789L15.3126 28.4447L17.2821 21.1195Z"
      fill="url(#paint3_linear_258_4435)"
      fill-opacity="0.88"
    />
    <path
      d="M14.5696 21.072C13.7083 20.8184 12.9422 20.3436 12.3375 19.7135L7.38495 24.6493L9.18255 26.4408L14.5696 21.072Z"
      fill="url(#paint4_linear_258_4435)"
      fill-opacity="0.88"
    />
    <path
      d="M12.2961 19.6699C11.7018 19.0358 11.269 18.2496 11.0629 17.3762L4.22116 19.2032L4.87913 21.6504L12.2961 19.6699Z"
      fill="url(#paint5_linear_258_4435)"
      fill-opacity="0.88"
    />
    <defs>
      <linearGradient
        id="paint0_linear_258_4435"
        x1="16.0001"
        y1="3.55579"
        x2="16.0001"
        y2="41.2962"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.313079" stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_258_4435"
        x1="16.0001"
        y1="3.55579"
        x2="16.0001"
        y2="41.2962"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.313079" stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_258_4435"
        x1="16.0001"
        y1="3.55579"
        x2="16.0001"
        y2="41.2962"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.313079" stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_258_4435"
        x1="16.0001"
        y1="3.55579"
        x2="16.0001"
        y2="41.2962"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.313079" stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_258_4435"
        x1="16.0001"
        y1="3.55579"
        x2="16.0001"
        y2="41.2962"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.313079" stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_258_4435"
        x1="16.0001"
        y1="3.55579"
        x2="16.0001"
        y2="41.2962"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.313079" stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default Synergy;
