import React, { useEffect, useLayoutEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { httpClient } from "utils/HttpClient";
import CaretLeftIcon from "components/Icons/CaretLeft";
import ProhibitInsetIcon from "components/Icons/ProhibitInset";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import Notepad from "components/Icons/Notepad";

import { Container, SettingsRowContainer } from "./StyledTransaction";
import "./TransactionDetails.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PendingIcon from "@mui/icons-material/Pending";
import { useTranslation } from "react-i18next";

import {
  TableContainer,
  Paper,
  Table,
  Tab,
  Tabs,
  TableRow,
  TableBody,
  TableCell,
  Button,
  Box,
  Alert,
  Typography,
  Tooltip,
} from "@mui/material";
import { URL, TRANSACTION_DETAILS_ENDPOINT } from "constants/Constants";
import processorIcons from "utils/ProcessorIcons";
import { StyledTableCell, StyledTableRow } from "utils/Styles";
import Logout from "Logout";

const TransactionDetails = ({
  id,
  transactionType,
  handlingDetailsClose,
  transactionsData,
}) => {
  useLayoutEffect(() => {
    fetchTransactionData();
  }, []);
  const [transactions, setTransactions] = useState([]);
  const [tableHeadings, setTableHeadings] = useState([]);
  const [expandedCapture, setExpandedCapture] = useState(null);
  const [expandedRefund, setExpandedRefund] = useState(null);
  const [warning, setWarning] = useState(false);
  const [breadcrumbList, setBreadcrumbList] = React.useState([
    { title: "Transactions", component: <Notepad /> },
  ]);
  const { t } = useTranslation();

  useEffect(() => {
    transactionsData?.map((eachItem) => {
      if (eachItem.id === id) {
        setTransactions(eachItem);
      }
    });
  }, []);

  const handleClose = () => {
    handlingDetailsClose();
  };
  const handleCaptureClick = (newValue) => {
    setExpandedCapture(newValue === expandedCapture ? null : newValue);
  };

  const handleRefundClick = (newValue) => {
    setExpandedRefund(newValue === expandedRefund ? null : newValue);
  };
  const camelToWords = (camelCaseString) =>
    camelCaseString
      .replace(/([A-Z])/g, " $1")
      .trim()
      .replace(/^\w/, (c) => c.toUpperCase());

  const fetchTransactionData = () => {
    httpClient
      .fetchData(
        `${URL}${TRANSACTION_DETAILS_ENDPOINT}?&type=payment&transactionid=${id}`,
        "GET"
      )
      .then((response) => {
        // Check if the status code is 200
        //console.log(response, "res");
        // setTransactions(response);
        if (response.length > 0) {
          setTableHeadings(Object.keys(response[0]));
          // Check if the status code is 401
          if (response.status === 401) {
            Logout();
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });
  };

  const renderButton = () => {
    switch (transactions?.status) {
      case "COMPLETED":
        return (
          <Button
            variant="contained"
            color="success"
            startIcon={<CheckCircleIcon />}
            className="status-button"
          >
            {transactions?.status}
          </Button>
        );
      case "PENDING":
        return (
          <Button
            variant="contained"
            color="warning"
            startIcon={<PendingIcon />}
            className="status-button"
          >
            {transactions?.status}
          </Button>
        );
      case "FAILED":
        return (
          <Button
            variant="contained"
            color="error"
            startIcon={<ErrorOutlineIcon />}
            className="status-button"
          >
            {transactions?.status}
          </Button>
        );
      case "ERROR":
        return (
          <Button
            variant="contained"
            color="error"
            startIcon={<ErrorOutlineIcon />}
            className="status-button"
          >
            {transactions?.status}
          </Button>
        );
      case "FAILED":
        return (
          <Button
            variant="contained"
            color="error"
            startIcon={<ErrorOutlineIcon />}
            className="status-button"
          >
            {transactions?.status}
          </Button>
        );
      default:
        return (
          <Button variant="contained" color="success" className="status-button">
            {transactions?.status}
          </Button>
        );
    }
  };

  return (
    <>
      <div className="toolbar py-2">
        <Breadcrumb list={breadcrumbList} />
      </div>
      <div className="container-xxl">
        <div className="card pt-6">
          {warning && (
            <Alert severity="error">{t("TRANSACTION_FETCH_ALERT")}</Alert>
          )}

          <div className="card-header border-0 pt-6">
            {/* <div className="breadcrumb-containers">
             <Breadcrumb list={breadcrumbList} />
           </div> */}
            <div className="card-title">
              <div class="d-flex">
                <Tooltip title="Back">
                  <Box onClick={handleClose} sx={{ cursor: "pointer" }}>
                    <CaretLeftIcon className="caret-left-icon" />
                  </Box>
                </Tooltip>
                {/* <Typography className="ref-id-text">
               Transaction details <br />
               Ref Id :{transactions?.merchantReferenceId}
             </Typography> */}
                <Typography className="ref-id-text pe-3">
                  {t("TRANSACTION_DETAILS")}
                  <span className="break-span">
                    {t("REF_ID")}: {transactions?.merchantReferenceId}
                  </span>
                </Typography>
                <Tooltip title={transactions?.status}>
                  {/* <Button
                 className="failed-btn"            
                 startIcon={<ProhibitInsetIcon />}
               >
                 {transactions?.status}
               </Button> */}
                  {renderButton()}
                </Tooltip>
              </div>
            </div>
          </div>

          <Container className="card-body border-bottom">
            <Typography className="creds-title">{t("BASIC_INFO")}</Typography>
            <SettingsRowContainer className="setttings-row-container">
              <Box
                className={
                  transactions?.amount?.value !== undefined &&
                  transactions?.amount?.currencyCode !== undefined
                    ? "each-container"
                    : "hide-container"
                }
              >
                <h6 className="label-text">{t("AMOUNT")}</h6>
                <Typography className="get-user-text">
                  {transactions?.amount?.value}
                  {transactions?.amount?.currencyCode}
                </Typography>
              </Box>
              <Box
                className={
                  transactions?.merchantReferenceId !== undefined
                    ? "each-container"
                    : "hide-container"
                }
              >
                <h6 className="label-text">{t("REFERENCE_NUMBER")}</h6>
                <Typography className="get-user-text">
                  {transactions?.merchantReferenceId}
                </Typography>
              </Box>
              <Box
                className={
                  transactions?.processorReconId !== undefined
                    ? "each-container"
                    : "hide-container"
                }
              >
                <h6 className="label-text">{t("processorReconId")}</h6>
                <Typography className="get-user-text">
                  {transactions?.processorReconId}
                </Typography>
              </Box>

              <Box
                className={
                  transactions?.processorTransactionId !== undefined
                    ? "each-container"
                    : "hide-container"
                }
              >
                <h6 className="label-text">{t("PROCESSOR_TRANSACTION_ID")}</h6>
                <Typography className="get-user-text">
                  {transactions?.processorTransactionId}
                </Typography>
              </Box>

              <Box
                className={
                  transactions?.processorCode !== undefined
                    ? "each-container"
                    : "hide-container"
                }
              >
                <h6 className="label-text">{t("PROCESSOR")}</h6>
                <img
                  style={{ height: 50, width: 80 }}
                  src={processorIcons[transactions?.processorCode] || null}
                />
              </Box>

              <Box
                className={
                  transactions?.status !== undefined
                    ? "each-container"
                    : "hide-container"
                }
              >
                <h6 className="label-text">{t("STATUS")}</h6>
                <Typography className="get-user-text">
                  {transactions?.status}
                </Typography>
              </Box>
            </SettingsRowContainer>
          </Container>

          <Container className="card-body border-bottom">
            <Typography className="creds-title">
              {t("CUSTOMER_DETAILS")}
            </Typography>
            <SettingsRowContainer className="setttings-row-container">
              {transactions &&
                transactions.customer &&
                Object.entries(transactions.customer)?.map(
                  ([key, value], index) => {
                    return (
                      <Box
                        className={
                          typeof value !== "object"
                            ? "each-container"
                            : value.hasOwnProperty("countryCode") &&
                              value.hasOwnProperty("number") !== ""
                            ? "each-container"
                            : value.hasOwnProperty("number") !== ""
                            ? "each-container"
                            : "hide-container"
                        }
                        key={index}
                      >
                        <h6 className="label-text">{camelToWords(key)}</h6>
                        <Typography className="get-user-text">
                          {typeof value === "object" &&
                          value.hasOwnProperty("countryCode") &&
                          value.hasOwnProperty("number")
                            ? `${value.countryCode} ${value.number}`
                            : typeof value === "object"
                            ? value.number //JSON.stringify(value.number)
                            : value}
                        </Typography>
                      </Box>
                    );
                  }
                )}
            </SettingsRowContainer>
          </Container>

          <Container
            className={
              transactions?.paymentMethod !== undefined
                ? "card-body border-bottom"
                : "frame-hide"
            }
          >
            <Typography className="creds-title">
              {t("PAYMENT_INSTRUMENT_DETAILS")}
            </Typography>
            <SettingsRowContainer className="setttings-row-container">
              <Box
                className={
                  transactions?.paymentMethod !== undefined
                    ? "each-container"
                    : "hide-container"
                }
              >
                <h6 className="label-text">{t("PAYMENT_METHOD")}</h6>
                <Typography className="get-user-text">
                  {transactions?.paymentMethod}
                </Typography>
              </Box>

              {transactions?.paymentMethodDetails?.card && ( //true
                <>
                  <Box
                    className={
                      transactions?.paymentMethodDetails?.card?.brand !==
                      undefined
                        ? "each-container"
                        : "hide-container"
                    }
                  >
                    <h6 className="label-text">{t("CARD_BRAND")}</h6>
                    <Typography className="get-user-text">
                      {transactions?.paymentMethodDetails?.card?.brand}
                    </Typography>
                  </Box>
                  <Box
                    className={
                      transactions?.paymentMethodDetails?.card?.last4 !==
                      undefined
                        ? "each-container"
                        : "hide-container"
                    }
                  >
                    <h6 className="label-text">{t("CARD_NUMBER")}</h6>
                    <Typography className="get-user-text">
                      **** **** ****{" "}
                      {transactions?.paymentMethodDetails?.card?.last4}
                    </Typography>
                  </Box>
                </>
              )}
            </SettingsRowContainer>
          </Container>
          <Container
            className={
              typeof transactions?.paymentMethodDetails?.card?.threeDSecure ===
              "object"
                ? "card-body border-bottom"
                : "frame-hide"
            }
          >
            <Typography className="creds-title">
              {t("THREE_D_SECURE_DETAILS")}
            </Typography>
            <SettingsRowContainer className="setttings-row-container">
              <p>
                {console.log(
                  typeof transactions?.paymentMethodDetails?.card
                    ?.threeDSecure === "object" && "Test Ok"
                )}
              </p>
              <p>
                {console.log(
                  transactions?.paymentMethodDetails?.card?.threeDSecure
                )}
              </p>
              {transactions &&
                typeof transactions?.paymentMethodDetails?.card
                  ?.threeDSecure === "object" &&
                Object.entries(
                  transactions?.paymentMethodDetails?.card?.threeDSecure || {}
                ).map(([key, value]) => {
                  console.log(camelToWords(key), value);
                  return (
                    <>
                      <Box
                        className={
                          (typeof transactions?.paymentMethodDetails?.card
                            ?.threeDSecure ===
                            "object") !==
                          undefined
                            ? "each-container"
                            : "hide-container"
                        }
                      >
                        <h6 className="label-text">{camelToWords(key)}</h6>
                        <Typography className="get-user-text">
                          {value}
                        </Typography>
                      </Box>
                    </>
                  );
                })}
            </SettingsRowContainer>
          </Container>

          <Container className="card-body border-bottom">
            <Typography className="creds-title">{t("INFOMATION")}</Typography>
            <SettingsRowContainer className="setttings-row-container">
              <Box
                className={
                  transactions?.description !== undefined
                    ? "each-container"
                    : "hide-container"
                }
              >
                <h6 className="label-text">{t("DESCRIPTION")}</h6>
                <Typography className="get-user-text">
                  {transactions?.description}
                </Typography>
              </Box>

              <Box
                className={
                  transactions?.transactionTime !== undefined
                    ? "each-container"
                    : "hide-container"
                }
              >
                <h6 className="label-text">{t("CREATION_DATE")}</h6>
                <Typography className="get-user-text">
                  {transactions?.transactionTime}
                </Typography>
              </Box>
            </SettingsRowContainer>
          </Container>

          {transactions &&
            transactions.captures &&
            transactions.captures.length > 0 && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    backgroundColor: "#4e515c",
                    color: "white",
                    height: "10px",
                  }}
                >
                  {t("CAPTURES")}
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 700, marginTop: "7px" }}
                      aria-label="customized table"
                    >
                      <TableBody>
                        <Tabs
                          value={expandedCapture}
                          onChange={(event, newValue) =>
                            handleCaptureClick(newValue)
                          }
                          variant="scrollable"
                          scrollButtons="auto"
                        >
                          {transactions?.captures &&
                            Object.keys(transactions.captures).map((key) => (
                              <Tab
                                key={key}
                                label={`Capture ${Number(key) + 1}`}
                                value={key}
                              />
                            ))}
                        </Tabs>

                        {expandedCapture && (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                                border: "1px solid #ccc",
                                borderRadius: "8px",
                                padding: "16px",
                                marginBottom: "16px",
                              }}
                            >
                              <div>
                                <h3>{t("STATUS")}</h3>
                                <p>
                                  {
                                    transactions?.captures[expandedCapture]
                                      ?.status
                                  }
                                </p>
                              </div>
                              <div>
                                <h3>{t("AMOUNT")}</h3>
                                <p>
                                  {
                                    transactions?.captures[expandedCapture]
                                      ?.amount?.currencyCode
                                  }
                                  {
                                    transactions?.captures[expandedCapture]
                                      ?.amount?.value
                                  }
                                </p>
                              </div>
                              <div>
                                <h3>{t("REFERENCE_NUMBER")}</h3>
                                <p>
                                  {
                                    transactions?.captures[expandedCapture]
                                      ?.merchantReferenceId
                                  }
                                </p>
                              </div>
                              <div>
                                <h3>{t("processorReconId")}</h3>
                                <p>
                                  {
                                    transactions?.captures[expandedCapture]
                                      ?.processorReconId
                                  }
                                </p>
                              </div>
                              <div>
                                <h3>{t("PROCESSOR_TRANSACTION_ID")}</h3>
                                <p>
                                  {
                                    transactions?.captures[expandedCapture]
                                      ?.processorTransactionId
                                  }
                                </p>
                              </div>
                            </Box>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                      sx={{
                        backgroundColor: "#4e515c",
                        color: "white",
                        height: "10px",
                      }}
                    >
                      {t("INFOMATION")}
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 700, marginTop: "7px" }}
                          aria-label="customized table"
                        >
                          <TableBody>
                            <StyledTableRow>
                              <StyledTableCell>
                                {t("DESCRIPTION")}
                              </StyledTableCell>
                              <StyledTableCell>
                                {
                                  transactions?.captures[expandedCapture]
                                    ?.description
                                }
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledTableCell>
                                {t("CREATION_DATE")}
                              </StyledTableCell>
                              <StyledTableCell>
                                {
                                  transactions?.captures[expandedCapture]
                                    ?.transactionTime
                                }
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </AccordionDetails>
              </Accordion>
            )}

          {transactions &&
            transactions.refunds &&
            transactions.refunds.length > 0 && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{
                    backgroundColor: "#4e515c",
                    color: "white",
                    height: "10px",
                  }}
                >
                  {t("REFUNDS")}
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 700, marginTop: "7px" }}
                      aria-label="customized table"
                    >
                      <TableBody>
                        <Tabs
                          value={expandedRefund}
                          onChange={(event, newValue) =>
                            handleRefundClick(newValue)
                          }
                          variant="scrollable"
                          scrollButtons="auto"
                        >
                          {transactions?.refunds &&
                            Object.keys(transactions.refunds).map((key) => (
                              <Tab
                                key={key}
                                label={`Refund ${Number(key) + 1}`}
                                value={key}
                              />
                            ))}
                        </Tabs>

                        {expandedRefund && (
                          <>
                            <TableRow>
                              <TableCell>{t("PROCESSOR")}</TableCell>
                              <TableCell>
                                <img
                                  style={{ height: 48, width: 48 }}
                                  src={
                                    processorIcons[
                                      transactions?.refunds[expandedRefund]
                                        ?.processorCode
                                    ] || null
                                  }
                                  alt="Processor"
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{t("STATUS")}</TableCell>
                              <TableCell>
                                {transactions?.refunds[expandedRefund]?.status}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{t("AMOUNT")}</TableCell>
                              <TableCell>
                                {
                                  transactions?.refunds[expandedRefund]?.amount
                                    ?.currencyCode
                                }
                                {
                                  transactions?.refunds[expandedRefund]?.amount
                                    ?.value
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{t("REFERENCE_NUMBER")}</TableCell>
                              <TableCell>
                                {
                                  transactions?.refunds[expandedRefund]
                                    ?.merchantReferenceId
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{t("processorReconId")}</TableCell>
                              <TableCell>
                                {
                                  transactions?.refunds[expandedRefund]
                                    ?.processorReconId
                                }
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            )}
        </div>
      </div>
    </>
  );
};

export default TransactionDetails;