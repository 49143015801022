import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import "./App.css"; // Optional: Add some styling for better visuals.

const PaymentPreference = ({onDataFromChild}) => {
    const [methods, setMethods] = useState([
        "Virtual Account",
        "Credit & Debit Cards",
        "Qris",
        "Wallets",
        "Over-The-Counter (OTC)",
    ]);

    const handleDragEnd = (result) => {
        if (!result.destination) return; // If dropped outside, do nothing.

        const updatedMethods = Array.from(methods);
        const [removed] = updatedMethods.splice(result.source.index, 1);
        updatedMethods.splice(result.destination.index, 0, removed);

        setMethods(updatedMethods);
        onDataFromChild(updatedMethods);
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="paymentMethods">
                {(provided) => (
                    <ul
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{ listStyle: "none", padding: 0 }}
                    >
                        {methods.map((method, index) => (
                            <Draggable key={method} draggableId={method} index={index}>
                                {(provided) => (
                                    <li
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                            padding: "10px",
                                            margin: "5px 0",
                                            backgroundColor: "#f0f0f0",
                                            borderRadius: "5px",
                                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                            ...provided.draggableProps.style,
                                        }}
                                    >
                                        {method}
                                    </li>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default PaymentPreference;
