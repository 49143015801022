import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useIdle } from 'react-use';
import Home from "./components/Home/Home";
import Logout from "./Logout";
import { httpClient } from "./utils/HttpClient.js";
import { KCClient } from "./utils/KC.js";
import Keycloak from "keycloak-js";
import {
  KEYCLOAK_URL,
  REALM,
  CLIENT_ID,
} from "constants/Constants";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { setMerchantId } from "./redux/reducers/merchant";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import './i18n';
console.log(process.env);

function App() {
 
  const [authenticated, setAuthenticated] = useState(false);
  const dispatch = useDispatch();
  const isIdle = useIdle(10*60*1000);
  useEffect(() => {
    const keycloakInitParams = {
      url: KEYCLOAK_URL,
      realm: REALM,
      clientId: CLIENT_ID,
    };

    const kc = new Keycloak(keycloakInitParams);
    console.log(kc, "kc");

    kc.onTokenExpired = () => {
      console.log("token expired");
      kc.updateToken(10)
        .then((refreshed) => {
          console.log("refreshed - " + refreshed);
          httpClient.setHeader("Authorization", "Bearer " + kc.token);
        })
        .catch(() => {
          console.error("Failed to refresh token");
          kc.logout();
          // reload the home or login screen
        });
    };

    kc.init({
      onLoad: "login-required",
      checkLoginIframe: false,
     
    }).then((auth) => {
      KCClient.setKC(kc);

      if (!auth) {
        window.location.reload();
      } else {
        setAuthenticated(true);
        console.log("authenticated");
        const getToken = jwtDecode(kc.token);
        console.log(getToken.merchantId, "get");
        
        dispatch(setMerchantId(getToken.merchantId));
        console.log(kc);
        httpClient.setHeader("Authorization", "Bearer " + kc.token);
      }
    });
  }, []); // Run only once on component mount

  useEffect(() => {
    // If user is idle for more than 10 minutes, perform an action (e.g., logout)
    if (isIdle) {
      console.log("User has been idle for 10 minutes. Logging out...");
      Logout();
      
    }
  }, [isIdle]);

  if (!authenticated) {
    // Return a loading indicator or a login screen while waiting for authentication
    return (
      <div className="container-login">
        <img
          className="loading-indicator"
          src="logos/Loader.svg"
          alt="Loading..."
        />
      </div>
    );
  }

  return (
    <Router>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/index.html" element={<Home />} />
        </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
