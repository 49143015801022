import React, { useEffect, useState } from "react";
import "./RulesSettingCard.css";
import RulesCondition from "../RulesCondition/RulesCondition";
import {
  MenuItem,
  FormControl,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Box,
  IconButton,
  Menu,
  Select,
  Grid,
} from "@mui/material";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import Tree from "../../Icons/Tree";
import { httpClient } from "utils/HttpClient";
import { URL, RULES_ENDPOINT } from "constants/Constants";
import { processors } from "utils/processors";
import { cloneDeep, get, isArray } from "lodash";
import Plus from "components/Icons/Plus";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SouthSharpIcon from "@mui/icons-material/SouthSharp";
import NorthSharpIcon from "@mui/icons-material/NorthSharp";
import { FlashAuto } from "@mui/icons-material";
import { useProcessorData } from "../RulesCondition/ProcessorFunctionProvider";
import { useTranslation } from "react-i18next";

function RulesSettingCard(
  {
    data,
    onDataDeleteChange,
    index,
    fetchData,
    setIsLoading,
    arrayData,
    setRules,
    savedFirstRule,
    getProcessorData,
    setSelectParamBasedFilters,
  },
  props
) {
  const [ruleData, setRuleData] = useState({});
  const [showAddRule, setShowAddRule] = useState(false);
  const [error, setError] = useState({
    nameError: "",
    resultError: "",
    statusError: "",
  });
  const [conditionError, setConditionError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [breadcrumbList, setBreadcrumbList] = React.useState([
    { title: "Rules", component: <Tree /> },
  ]);
  const { t } = useTranslation();

  const [processorText, setProcessorText] = useState();
  const [processorButtonHide, setProcessorButtonHide] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [getData, setGetData] = useState(getProcessorData);
  const [editRule, setEditRule] = useState(true);
  const [updateProcessorText, setUpdateProcessorText] = useState();
  const [changeProcessorText, setChangeProcessorText] = useState(false);
  const open = Boolean(anchorEl);
  const [processorAnchorEl, setProcessorAnchorEl] = useState(null);
  const [changeEditSaveFirstRule, setChangeEditSaveFirstRule] = useState(true);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [addProcessorErrorMsg, setAddProcessorErrorMsg] = useState(false);
  const [selectPaymentMethods, setSelectPaymentMethod] = useState();
  const [savedPaymentMethodsUpdate, setSavedPaymentMethodsUpdate] = useState();

  const handleText = (value) => {
    setProcessorText(value);
    handleClose();
    setProcessorButtonHide(true);
    setAddProcessorErrorMsg(false);
  };

  console.log(updateProcessorText, "update processor");
  // console.log(processorText, "add processor");
  // console.log(savedPaymentMethodsUpdate, "savedPaymentData update");

  const { processorData } = useProcessorData();
  console.log(processorData, "in card");

  // console.log(getData, "getData");

  useEffect(() => {
    processorData?.map((eachItem) => {
      // if (eachItem.processorCode === processorText) {
      if (eachItem?.id === processorText?.id) {
        setSelectPaymentMethod(eachItem.paymentMethods);
      }
    });
  }, [processorText]);

  useEffect(() => {
    processorData?.map((eachItem) => {
      // if (eachItem.processorCode === updateProcessorText) {
      if (eachItem?.id === updateProcessorText?.id) {
        setSavedPaymentMethodsUpdate(eachItem.paymentMethods);
      }
    });
  }, [updateProcessorText]);

  console.log(
    selectPaymentMethods,
    "payment-methods check with",
    processorText || updateProcessorText
  );

  const handleMoveItem = (index, direction) => {
    const newArray = [...arrayData];
    const itemToMove = newArray[index];

    if (direction === "up" && index > 0) {
      newArray?.splice(index, 1);
      newArray?.splice(index - 1, 0, itemToMove);
    } else if (direction === "down" && index < newArray?.length - 1) {
      newArray?.splice(index, 1);
      newArray?.splice(index + 1, 0, itemToMove);
    }
    setRules(newArray);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setRuleData({
      ...data,
    });
  }, [data]);

  const recurse = (obj, value) => {
    for (let i = 0; i < obj?.length; i++) {
      if (obj[i].hasOwnProperty("nestedOperation")) {
        recurse(obj[i].conditions, value);
      } else {
        if (value.id === obj[i].id) {
          obj[i] = value;
        }
      }
    }
  };

  const handleEditSaveClick = (obj) => {
    setChangeEditSaveFirstRule(true);

    if (!isEditing) {
      const updatedObj = cloneDeep(obj);
      setIsEditing(true);
      if (
        updatedObj.condition.conditions &&
        isArray(updatedObj.condition.conditions)
      )
        setRuleData({ ...updatedObj });
      else {
        setRuleData({
          ...updatedObj,
          condition: {
            nestedOperation: "AND",
            conditions: [updatedObj.condition],
          },
        });
      }
      return;
    }
    setIsLoading(true);
    const validRule = cloneDeep(ruleData);
    if (!validateAndUpdateConditions(validRule.condition)) {
      setConditionError(true);
      setRuleData({ ...validRule });
      setIsLoading(false);
    }
    if (validateAndUpdateConditions(validRule.condition)) {
      let finalObject = cloneDeep(validRule);
      console.log(finalObject, "final");
      deleteProperties(finalObject);
      console.log(finalObject, ruleData, "Rule");
      if (finalObject?.condition?.conditions?.length === 1) {
        const updatedCondition = finalObject.condition.conditions[0];
        finalObject = {
          ...finalObject,
          condition: { ...updatedCondition },
        };
      }
      console.log(finalObject, "update-final-rule");
      httpClient
        .fetchData(
          `${URL}${RULES_ENDPOINT}/${ruleData?.id}`,
          "PUT",
          finalObject
        )
        .then((res) => {
          console.log("Rule updated:", res);
          fetchData();
          setIsEditing(false);
        })
        .catch((error) => {
          console.error("Error in updating rule:", error);
        });
    }
  };
  const handleAddClick = () => {
    setShowAddRule(true);
    // setIsEditing(true);
    setRuleData({
      id: new Date().getTime(),
      name: "",
      result: "",

      status: "ACTIVE",
      condition: {
        nestedOperation: "AND",
        conditions: [
          {
            id: new Date().getTime(),
            param: undefined,
            operation: undefined,
            value: undefined,
            isError: false,
            errorMessage: "",
          },
        ],
      },
    });
    setChangeEditSaveFirstRule(false);
  };

  const handleCancelClick = () => {
    setShowAddRule(false);
    setIsEditing(false);
    setEditRule(true);
    setProcessorButtonHide(false);
    setAddProcessorErrorMsg(false);
    setError((prev) => {
      return { ...prev, nameError: "" };
    });
  };

  function deleteProperties(obj) {
    // Define the properties to delete
    const propertiesToDelete = new Set(["isError", "errorMessage"]);

    // Recursive function to traverse and delete properties
    function traverseAndDelete(currentObj) {
      if (Array.isArray(currentObj)) {
        // If it's an array, loop through its elements
        currentObj.forEach((item) => traverseAndDelete(item));
      } else if (currentObj !== null && typeof currentObj === "object") {
        // If it's an object, loop through its properties
        Object.keys(currentObj).forEach((key) => {
          if (propertiesToDelete.has(key)) {
            // Delete the property if it's in the list
            delete currentObj[key];
          } else if (key === "id" && typeof currentObj[key] === "number") {
            // Delete the 'id' property only if its value is a number
            delete currentObj[key];
          } else {
            // Recursively call the function for nested objects
            traverseAndDelete(currentObj[key]);
          }
        });
      }
    }
    // Start the recursive deletion
    traverseAndDelete(obj);
  }

  const checkIfValidNameAndResult = (ruleData) => {
    const regexPattern = /^[A-Za-z0-9_-]+$/;
    if (ruleData.name.length === 0 && ruleData.result.length === 0) {
      //if (ruleData.name.length === 0 && ruleData.connectionName.length === 0) {
      setError({
        nameError: "Rule name should not be empty!",
        resultError: "Processor should not be empty!",
      });
      return false;
    } else if (
      !regexPattern.test(ruleData.name) &&
      ruleData.result.length === 0
      //ruleData.connectionName.length === 0
    ) {
      setError({
        nameError:
          "Rule name should not contain special characters except _ & -!",
        resultError: "Processor should not be empty!",
      });
      return false;
    } else if (ruleData.name.length === 0) {
      setError({ ...error, nameError: "Rule name should not be empty!" });
      return false;
    } else if (!regexPattern.test(ruleData.name)) {
      setError({
        ...error,
        nameError:
          "Rule name should not contain special characters except _ & -!",
      });
      return false;
    } else if (ruleData.result.length === 0) {
      //else if (ruleData.connectionName.length === 0) {
      setError({
        nameError: "",
        resultError: "Processor should not be empty!",
      });
      return false;
    } else {
      return true;
    }
  };
  function validateAndUpdateConditions(condition) {
    // Helper function to check if a string is empty
    const isEmpty = (str) => !str || str.trim() === "";

    // Variable to keep track of the validation status
    let noErrors = true;

    // Recursive function to validate and update each condition
    const validateAndUpdate = (cond) => {
      if (cond.conditions && Array.isArray(cond.conditions)) {
        // If there are nested conditions, validate and update each one recursively
        cond.conditions.forEach(validateAndUpdate);
      } else {
        // Initialize errorMessage as an empty array
        cond.errorMessage = [];

        // Check for empty fields and set isError flag and errorMessage accordingly
        if (isEmpty(cond.param)) {
          cond.isError = true;
          cond.errorMessage.push("Param cannot be empty");
          noErrors = false;
        }
        if (isEmpty(cond.operation)) {
          cond.isError = true;
          cond.errorMessage.push("Operation cannot be empty");
          noErrors = false;
        }
        if (isEmpty(cond.value)) {
          cond.isError = true;
          cond.errorMessage.push("Value cannot be empty");
          noErrors = false;
        }

        // Join error messages if there are multiple
        if (cond.errorMessage.length > 0) {
          cond.errorMessage = cond.errorMessage.join("; ");
        } else {
          delete cond.errorMessage; // Remove errorMessage if there are no errors
        }
      }
    };

    // Start the validation and update process
    validateAndUpdate(condition);

    // Return true if no errors were found, false otherwise
    return noErrors;
  }

  const handleAddRule = () => {
    // setIsLoading(true);
    setProcessorButtonHide(false);
    setAddProcessorErrorMsg(true);
    const validRule = cloneDeep(ruleData);
    if (!validateAndUpdateConditions(validRule.condition)) {
      setConditionError(true);
      setRuleData({ ...validRule });
      setIsLoading(false);
    }
    if (
      checkIfValidNameAndResult(ruleData) &&
      validateAndUpdateConditions(validRule.condition)
    ) {
      let finalObject = cloneDeep(validRule);
      deleteProperties(finalObject);
      if (finalObject?.condition?.conditions?.length === 1) {
        const updatedCondition = finalObject.condition.conditions[0];
        finalObject = {
          ...finalObject,
          condition: { ...updatedCondition },
        };
      }
      console.log(finalObject);

      if (processorButtonHide) {
        setShowAddRule(false);
        setIsEditing(false);
        httpClient
          .fetchData(`${URL}${RULES_ENDPOINT}`, "POST", {
            id: null,
            ...finalObject,
          })
          .then((res) => {
            console.log("New rule added:", res);
            fetchData();
          })
          .catch((error) => {
            console.error("Error adding new rule:", error);
            setIsLoading(false);
          });
      }
    }
  };

  const handleDeleteClick = () => {
    setIsLoading(true);
    httpClient
      .fetchData(`${URL}${RULES_ENDPOINT}/${ruleData.id}`, "DELETE")
      .then((res) => {
        console.log("Deleted rule:", res);
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting rule:", error);
        setIsLoading(false);
      });
  };

  const handleEnableDisableClick = (status) => {
    setIsLoading(false);

    httpClient
      .fetchData(`${URL}${RULES_ENDPOINT}/${ruleData.id}`, "PATCH", {
        status: status === "ACTIVE" ? "DISABLED" : "ACTIVE",
      })
      .then((res) => {
        console.log("Updated rule status:", res);
        setIsLoading(false);
        fetchData();
      })
      .catch((error) => {
        console.error("Error updating rule status:", error);
        setIsLoading(false);
      });
  };

  const handleDuplicateCall = (obj) => {
    const updatedObj = cloneDeep(obj);
    console.log(updatedObj, "object");
    setShowAddRule(true);
    setIsEditing(false);
    const updatedName = `${updatedObj.name}_COPY`;

    if (
      updatedObj.condition.conditions &&
      isArray(updatedObj.condition.conditions)
    )
      setRuleData({
        ...updatedObj,
        id: new Date().getTime(),
        name: updatedName,
      });
    else
      setRuleData({
        id: new Date().getTime(),
        name: updatedName,
        result: updatedObj.result,
        // connectionName: updatedObj.connectionName,
        condition: {
          nestedOperation: "AND",
          conditions: [updatedObj.condition],
        },
      });
  };

  const handleRuleNameChange = (event) => {
    setRuleData({
      ...ruleData,
      name: event.target.value,
    });
    setError({ ...error, nameError: "" });
  };

  const handleProcessorChange = (value) => {
    setRuleData({
      ...ruleData,
      result: value,
      //connectionName: value,
    });
    setError({ ...error, resultError: "" });
  };

  const handleStatusChange = (event) => {
    setRuleData({
      ...ruleData,
      status: event.target.value,
    });
    setError({ ...error, statusError: "" });
  };

  const getResultData = processorData?.map((each) => {
    // getData
    if (each.status === "ACTIVE") {
      return each.processorCode.toLowerCase();
    }
  });

  const Header = () => {
    return (
      // <>

      <div className="card-header border-0 pt-6">
        <div className="card-title">
          <div className="header-title">{t("RULES")}</div>
          <div className="header-sub-title">{t("RULES_INITIAL_TRACK")}</div>
        </div>

        <Tooltip title="Add New Rule">
          <div className={`card-toolbar`} onClick={handleAddClick}>
            <div className="d-flex justify-content-end">
              <button className="btn btn-primary">
                <i class="ki-duotone ki-plus fs-4"></i>
                {t("NEW_RULE")}
                {/* {Object.keys(data)?.length <= 0 ? "Add Rule" : "Add New Rule"} */}
              </button>
            </div>
          </div>
        </Tooltip>
      </div>

      //</>
    );
  };

  const handleProcessorClick = (event) => {
    setProcessorAnchorEl(event.currentTarget);
  };

  const handleProcessorClose = () => {
    setProcessorAnchorEl(null);
  };

  const handleProcessorSelect = () => {
    handleProcessorClose();
  };
  const handleProcessorText = (value) => {
    setUpdateProcessorText(value);
    handleProcessorClose();
  };

  const handleUpdateProcessorChange = (value) => {
    setRuleData({
      ...ruleData,
      result: value.toUpperCase(),
      // connectionName: value,
    });

    setError({ ...error, resultError: "" });
    setChangeProcessorText(true);
  };

  return (
    <div>
      <div key={ruleData.id}>
        {index === 0 && (
          <div>
            <div className="toolbar py-2" id="kt_toolbar">
              <div
                id="kt_toolbar_container"
                className="container-fluid container-fluid d-flex align-items-center"
              >
                <Breadcrumb list={breadcrumbList} />
              </div>{" "}
            </div>
            <div className="container-xxl">
              <div className="card">
                <Header />
              </div>
            </div>
          </div>
        )}

        {showAddRule && (
          <div className="container-xxl rulescontainer">
            <div className="card rule-box">
              <div className="card-header card-header-stretch">
                <div className="card-title d-flex align-items-center ">
                  <i class="ki-duotone ki-document fs-1 text-primary me-3 lh-0">
                    <span class="path1"></span>
                    <span class="path2"> </span>
                  </i>
                  <h3 class="fw-bold m-0 text-gray-800">{t("NEW_RULE")}</h3>
                </div>
              </div>
              <div className="nested-rules-container">
                <div className="conditions-main-container">
                  <div className="row">
                    <div className="col-sm-2 text-sm-end">
                      <p
                      // style={{
                      //   marginTop:
                      //     error.nameError.length > 0 ? "-18px" : "0px",
                      // }}
                      // className="new-rule-text"
                      >
                        {t("RULE")}
                      </p>
                    </div>
                    <div className="col-sm-10">
                      <FormControl fullWidth>
                        <TextField
                          className="new-rule-input-field"
                          fullWidth
                          label={t("RULE")}
                          variant="outlined"
                          onChange={handleRuleNameChange}
                          value={ruleData?.name}
                          key={index}
                          error={error.nameError.length > 0}
                          helperText={error.nameError}
                          // sx={{
                          //   width: "100%",
                          //   "& .MuiOutlinedInput-root": {
                          //     height: "40px",
                          //     "& input": {
                          //       height: "40px",
                          //       padding: "0 14px",
                          //     },
                          //   },
                          //   "& .MuiInputLabel-root": {
                          //     marginTop: "-0.5rem",
                          //     "&.Mui-focused": {
                          //       marginTop: "0px",
                          //     },
                          //   },
                          // }}
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-2 text-sm-end">
                      <p> {t("STATUS")}</p>
                    </div>
                    <div className="col-sm-10">
                      <FormControl fullWidth>
                        <RadioGroup
                          row
                          className="radiobtn"
                          aria-label="status"
                          name="status"
                          value={
                            ruleData.status === "ACTIVE"
                              ? "enabled"
                              : ruleData.status
                          }
                          onChange={handleStatusChange}
                        >
                          <FormControlLabel
                            value="enabled"
                            control={<Radio />}
                            label={t("ENABLED")}
                          />
                          <FormControlLabel
                            value="disabled"
                            control={<Radio />}
                            label={t("DISABLED")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-2 text-sm-end">
                      <p>{t("CONDITIONS")}</p>
                    </div>
                    <div className="col-sm-10">
                      <div className="condition-container">
                        {ruleData.condition?.conditions?.length > 0 ? (
                          <>
                            <RulesCondition
                              data={{ condition: ruleData.condition }}
                              level={0}
                              setRuleData={setRuleData}
                              ruleData={ruleData}
                              indexPath={[]}
                              conditionError={conditionError}
                              key={ruleData.id}
                              setConditionError={setConditionError}
                              ruleId={ruleData.id}
                              // isEditing={isEditing}
                              isEditing={
                                isEditing ? changeEditSaveFirstRule : true
                              }
                              processorText={processorText}
                              selectPaymentMethods={selectPaymentMethods}
                              savedPaymentMethodsUpdate={
                                savedPaymentMethodsUpdate
                              }
                              processorButtonHide={processorButtonHide}
                              addProcessorErrorMsg={addProcessorErrorMsg}
                              updateProcessorText={updateProcessorText}
                            />
                          </>
                        ) : (
                          <RulesCondition
                            data={{
                              condition: {
                                nestedOperation: "AND",
                                conditions: [
                                  ruleData?.condition?.conditions[0] || {
                                    id: new Date().getTime(),
                                    param: undefined,
                                    operation: undefined,
                                    value: undefined,
                                    isError: false,
                                    errorMessage: "",
                                  },
                                ],
                              },
                            }}
                            level={0}
                            setRuleData={setRuleData}
                            ruleData={ruleData}
                            indexPath={[]}
                            conditionError={conditionError}
                            key={ruleData.id}
                            setConditionError={setConditionError}
                            ruleId={ruleData.id}
                            isEditing={
                              isEditing ? changeEditSaveFirstRule : true
                            }
                            processorText={processorText}
                            addProcessorErrorMsg={addProcessorErrorMsg}
                          />
                        )}
                        <br></br>
                        <div
                          style={{
                            marginLeft: "100px",
                            marginTop: "7px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <Button
                                sx={{
                                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                  padding: "12px 13px",
                                  marginTop: "5px",
                                }}
                                className="select-connection-button"
                                id="fade-button"
                                aria-controls={open ? "fade-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={(event) => {
                                  handleClick(event);
                                }}
                              >
                                <Typography className="processor-button">
                                  {t("SELECT_CONNECTION")}
                                </Typography>
                                <ExpandMoreIcon sx={{ marginLeft: "10px" }} />
                              </Button>
                              <p
                                className={
                                  addProcessorErrorMsg
                                    ? "add-processor-error-text"
                                    : "hide-error-text"
                                }
                              >
                                {t("SELECT_CONNECTION")}
                              </p>

                              <Menu
                                id="fade-menu"
                                MenuListProps={{
                                  "aria-labelledby": "fade-button",
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={Fade}
                              >
                                {processorData
                                  ?.sort((a, b) => {
                                    return a.processorCode.localeCompare(
                                      b.processorCode
                                    );
                                  })
                                  ?.map((processor) => {
                                    console.log(processor.name, "connection");
                                    return (
                                      // getResultData?.includes(
                                      //   processor?.displayName.toLowerCase()
                                      // ) && (
                                      <MenuItem
                                        // sx={{ width: "165px" }}
                                        sx={{
                                          "&:hover": {
                                            backgroundColor:
                                              "rgba(0, 123, 255, 0.1)",
                                            color: "#1b84ff",
                                          },
                                        }}
                                        onClick={() => {
                                          handleText(processor);
                                          handleProcessorChange(
                                            //processor.processorCode
                                            processor?.name
                                          );
                                        }}
                                        key={processor.displayName}
                                        value={processor.processorCode}
                                      >
                                        {`${processor.name} (${processor.processorCode})`}
                                      </MenuItem>
                                    );
                                    // );
                                  })}
                              </Menu>
                            </div>

                            {processorButtonHide && (
                              <Button
                                variant="outlined"
                                sx={{ marginTop: "6px" }}
                                className="select-new-processor-text"
                                disabled
                              >
                                {/* {processorText.processorCode} */}
                                {`${processorText.name} (${processorText.processorCode})`}
                              </Button>
                            )}
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rules-settings-buttons-container">
                <button
                  className=" btn btn-sm btn-flex btn-light-primary"
                  onClick={handleCancelClick}
                >
                  {t("CANCEL")}
                </button>
                <button
                  className=" btn btn-sm btn-flex btn-light-primary"
                  onClick={handleAddRule}
                >
                  {t("ADD")}
                </button>
              </div>
            </div>
          </div>
        )}

        <div>
          {Object.keys(data)?.length !== 0 && (
            <div className="container-xxl rulescontainer">
              <div className="">
                <Box
                  className=""
                  key={data.id}
                  // sx={{ padding: { xs: "10px", sm: "20px" } }}
                >
                  <Box className="card rule-box">
                    <Grid
                      className="card-header card-header-stretch"
                      // container
                      // alignItems="center"
                      // justifyContent="space-between"
                      // sx={{ marginTop: "10px" }}
                    >
                      <Grid className="card-title d-flex align-items-center">
                        {/* <Typography
                      className=""
                     
                    > */}
                        <i class="ki-duotone ki-document fs-1 text-primary me-3 lh-0">
                          <span class="path1"></span>
                          <span class="path2"> </span>
                        </i>
                        <h3 className="fw-bold m-0 text-gray-800">
                          {t("RULE")}: {data?.name}
                        </h3>
                        {/* </Typography> */}
                      </Grid>
                      <Grid className="card-toolbar">
                        <Box
                        // sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          {/* <IconButton
                        onClick={() => handleMoveItem(index, "up")}
                        disabled={index === 0}
                      > */}
                          {/* <NorthSharpIcon /> */}
                          <i
                            onClick={() => handleMoveItem(index, "up")}
                            disabled={index === 0}
                            class="ki-duotone ki-up-square fs-1 text-primary"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                          <i
                            onClick={() => handleMoveItem(index, "down")}
                            disabled={index === 0}
                            class="ki-duotone ki-down-square fs-1 text-primary"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                          {/* </IconButton> */}
                          {/* <IconButton
                        onClick={() => handleMoveItem(index, "down")}
                        disabled={index === arrayData?.length - 1}
                      >
                        <SouthSharpIcon />
                      </IconButton> */}
                        </Box>
                      </Grid>
                    </Grid>
                    <Box className="nested-rules-container">
                      <Box
                        className="conditions-main-container"
                        sx={{ marginTop: "10px" }}
                      >
                        <RulesCondition
                          data={{
                            condition: Array.isArray(
                              data?.condition?.conditions
                            )
                              ? data.condition
                              : {
                                  nestedOperation: "AND",
                                  conditions: [data.condition],
                                },
                          }}
                          level={0}
                          setRuleData={setRuleData}
                          ruleData={ruleData}
                          indexPath={[]}
                          conditionError={conditionError}
                          setConditionError={setConditionError}
                          key={data.id}
                          ruleId={data.id}
                          isEditing={isEditing}
                          selectPaymentMethods={selectPaymentMethods}
                          savedPaymentMethodsUpdate={savedPaymentMethodsUpdate}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "15px",
                          // marginTop: "-8px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Button
                            sx={{
                              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                              padding: "12px 13px",
                              width: "100%",
                              maxWidth: "200px",
                            }}
                            className="processor-button"
                            disabled={editRule}
                            onClick={handleProcessorClick}
                          >
                            {t("CONNECTION_NAME")}
                          </Button>
                          <Menu
                            anchorEl={processorAnchorEl}
                            open={Boolean(processorAnchorEl)}
                            onClose={handleProcessorClose}
                          >
                            {processorData
                              ?.sort((a, b) =>
                                a.processorCode.localeCompare(b.processorCode)
                              )
                              ?.map((processor) => (
                                <MenuItem
                                  // sx={{ width: "165px" }}
                                  onClick={() => {
                                    handleProcessorText(processor);
                                    handleUpdateProcessorChange(
                                      processor?.name
                                    );
                                    handleProcessorSelect();
                                  }}
                                  key={processor.displayName}
                                  value={processor.processorCode}
                                >
                                  {`${processor.name} ${processor.processorCode}`}
                                </MenuItem>
                              ))}
                          </Menu>
                        </Box>
                        <Button
                          className="selected-processor-text"
                          sx={{ marginLeft: "10px" }}
                          variant="outlined"
                          disabled
                        >
                          {changeProcessorText
                            ? `${updateProcessorText?.name} ${updateProcessorText?.processorCode}`
                            : data?.result}
                        </Button>
                      </Box>
                    </Box>

                    <Box
                      className="rules-settings-buttons-container"
                      sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
                    >
                      <Button
                        onClick={() => {
                          handleEditSaveClick(data);
                          setEditRule(false);
                        }}
                        disabled={data?.status === "DISABLED"}
                        className="btn btn-sm btn-flex btn-light-primary"
                        // sx={{
                        //   width: { xs: "5%", sm: "auto" },
                        //   marginBottom: { xs: "10px", sm: "0" },
                        // }}
                      >
                        {isEditing ? t("SAVE") : t("EDIT")}
                      </Button>
                      <Button
                        onClick={() => handleEnableDisableClick(data.status)}
                        className="btn btn-sm btn-flex btn-light-primary"
                        // sx={{
                        //   width: { xs: "5%", sm: "auto" },
                        //   marginBottom: { xs: "10px", sm: "0" },
                        // }}
                      >
                        {data.status === "ACTIVE" ? t("DISABLE") : t("ENABLE")}
                      </Button>
                      <Button
                        onClick={() => handleDuplicateCall(data)}
                        className="btn btn-sm btn-flex btn-light-primary"

                        // sx={{
                        //   width: { xs: "5%", sm: "auto" },
                        //   marginBottom: { xs: "10px", sm: "0" },
                        // }}
                      >
                        {t("DUPLICATE")}
                      </Button>
                      <Button
                        onClick={() => handleDeleteClick()}
                        className="btn btn-sm btn-flex btn-light-primary"
                        // sx={{
                        //   width: { xs: "5%", sm: "auto" },
                        //   marginBottom: { xs: "10px", sm: "0" },
                        // }}
                      >
                        {t("DELETE")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RulesSettingCard;
