import React, { useState } from "react";
import Select from "react-select";
import { Box, FormControl } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import "./MultiSelect.css";
import { useTranslation } from "react-i18next";

const MultiSelect = ({
  options,
  onChange,
  disabled,
  error,
  errorMessage,
  selectedValues,
  selectErrorMsg,
}) => {
  const [value, setValue] = useState();
  const { t } = useTranslation();

  const handleChange = (event) => {
    // console.log(event, "multi");
    onChange(event);
    setValue(event);
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      color: "black",
      // border: selectErrorMsg && error ? "1px solid red" : "",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,

        color: "black",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      borderRadius: "8px",
      color: "black",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "black",

      ":hover": {
        color: "black", // Set the color of the cross icon on hover
      },
    }),
  };

  return (
    <Box className="multi-select-dropdown">
      <FormControl variant="outlined" fullWidth size="small">
        <Select
          className="multi-select-dropdown multiselectChip "
          isDisabled={disabled}
          options={options}
          isMulti
          styles={colourStyles}
          onChange={handleChange}
          value={selectedValues}
        />

        {error && selectErrorMsg ? (
          <FormHelperText error>{errorMessage}</FormHelperText>
        ) : null}
      </FormControl>
    </Box>
  );
};

export default MultiSelect;