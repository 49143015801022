import React from "react";

export const DashboardIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1766 3.30232C14.3664 2.49033 13.4035 1.84674 12.3434 1.40868C11.2833 0.970624 10.147 0.746751 9 0.749973H8.96875C4.16172 0.76638 0.25 4.73435 0.25 9.58826V11.375C0.25 11.7065 0.381696 12.0244 0.616116 12.2589C0.850537 12.4933 1.16848 12.625 1.5 12.625H16.5C16.8315 12.625 17.1495 12.4933 17.3839 12.2589C17.6183 12.0244 17.75 11.7065 17.75 11.375V9.49997C17.7532 8.34793 17.5274 7.20673 17.0856 6.14276C16.6438 5.07878 15.9949 4.11325 15.1766 3.30232ZM16.5 11.375H8.35234L12.6305 5.49216C12.7281 5.3581 12.7684 5.19077 12.7426 5.02696C12.7168 4.86316 12.627 4.71631 12.493 4.61872C12.3589 4.52113 12.1916 4.48079 12.0278 4.50658C11.864 4.53236 11.7171 4.62216 11.6195 4.75622L6.80625 11.375H1.5V9.58826C1.5 9.34763 1.51172 9.11013 1.53359 8.87497H3.375C3.54076 8.87497 3.69973 8.80913 3.81694 8.69192C3.93415 8.57471 4 8.41573 4 8.24997C4 8.08421 3.93415 7.92524 3.81694 7.80803C3.69973 7.69082 3.54076 7.62497 3.375 7.62497H1.75547C2.56172 4.58904 5.19297 2.29372 8.375 2.02654V3.87497C8.375 4.04073 8.44085 4.1997 8.55806 4.31691C8.67527 4.43413 8.83424 4.49997 9 4.49997C9.16576 4.49997 9.32473 4.43413 9.44194 4.31691C9.55915 4.1997 9.625 4.04073 9.625 3.87497V2.02575C11.1755 2.15624 12.6472 2.76505 13.8368 3.76807C15.0264 4.77108 15.8751 6.11879 16.2656 7.62497H14.625C14.4592 7.62497 14.3003 7.69082 14.1831 7.80803C14.0658 7.92524 14 8.08421 14 8.24997C14 8.41573 14.0658 8.57471 14.1831 8.69192C14.3003 8.80913 14.4592 8.87497 14.625 8.87497H16.4742C16.4906 9.082 16.5 9.28982 16.5 9.49997V11.375Z"
      fill="#CACFD8"
    />
  </svg>
);

export const DashboardBreadcrumbIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1766 3.30232C14.3664 2.49033 13.4035 1.84674 12.3434 1.40868C11.2833 0.970624 10.147 0.746751 9 0.749973H8.96875C4.16172 0.76638 0.25 4.73435 0.25 9.58826V11.375C0.25 11.7065 0.381696 12.0244 0.616116 12.2589C0.850537 12.4933 1.16848 12.625 1.5 12.625H16.5C16.8315 12.625 17.1495 12.4933 17.3839 12.2589C17.6183 12.0244 17.75 11.7065 17.75 11.375V9.49997C17.7532 8.34793 17.5274 7.20673 17.0856 6.14276C16.6438 5.07878 15.9949 4.11325 15.1766 3.30232ZM16.5 11.375H8.35234L12.6305 5.49216C12.7281 5.3581 12.7684 5.19077 12.7426 5.02696C12.7168 4.86316 12.627 4.71631 12.493 4.61872C12.3589 4.52113 12.1916 4.48079 12.0278 4.50658C11.864 4.53236 11.7171 4.62216 11.6195 4.75622L6.80625 11.375H1.5V9.58826C1.5 9.34763 1.51172 9.11013 1.53359 8.87497H3.375C3.54076 8.87497 3.69973 8.80913 3.81694 8.69192C3.93415 8.57471 4 8.41573 4 8.24997C4 8.08421 3.93415 7.92524 3.81694 7.80803C3.69973 7.69082 3.54076 7.62497 3.375 7.62497H1.75547C2.56172 4.58904 5.19297 2.29372 8.375 2.02654V3.87497C8.375 4.04073 8.44085 4.1997 8.55806 4.31691C8.67527 4.43413 8.83424 4.49997 9 4.49997C9.16576 4.49997 9.32473 4.43413 9.44194 4.31691C9.55915 4.1997 9.625 4.04073 9.625 3.87497V2.02575C11.1755 2.15624 12.6472 2.76505 13.8368 3.76807C15.0264 4.77108 15.8751 6.11879 16.2656 7.62497H14.625C14.4592 7.62497 14.3003 7.69082 14.1831 7.80803C14.0658 7.92524 14 8.08421 14 8.24997C14 8.41573 14.0658 8.57471 14.1831 8.69192C14.3003 8.80913 14.4592 8.87497 14.625 8.87497H16.4742C16.4906 9.082 16.5 9.28982 16.5 9.49997V11.375Z"
      fill="#525866"
    />
  </svg>
);
