import React from "react";
import { Box, Button, Typography } from "@mui/material";
import ApiCredentials from "./ApiCredentials/ApiCredentials";
import Webhooks from "./Webhooks/Webhooks";
import CheckoutSettings from "./CheckoutSettings/CheckoutSettings";
import GooglePaySettings from "./GooglePaySettings/GooglePaySettings";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
// import DevelopersIcon from "components/Icons/Developers";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./Developers.css";
import SaveIcon from "../Icons/FloppyDisk";
import LoadingButton from "@mui/lab/LoadingButton";
import { Padding } from "@mui/icons-material";
import CheckoutConfiguration from "./CheckoutConfiguration/CheckoutConfiguration";
import { useTranslation } from "react-i18next";
import { DevelopersBreadcrumbIcon } from "components/Icons/Developers";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Developers = () => {
  const { t } = useTranslation();

  const [breadcrumbList, setBreadcrumbList] = React.useState([
    { title: t("DEVELOPERS"), component: <DevelopersBreadcrumbIcon /> },
  ]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <div>
      <div className="toolbar py-2" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid container-fluid d-flex align-items-center"
        >
          <Breadcrumb list={breadcrumbList} />
        </div>{" "}
      </div>
      <div className="container-xxl">
        <div className="card">
          <div className="card-header border-0 pt-6">
            <Box className="developer-header-container">
              <Box className="developer-row-container">
                <Box>
                  <Typography className="developer-text">
                    {t("DEVELOPERS")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </div>
          <div className="card-body py-4 line-tabs">
            <Box>
              <Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={t("API_KEYS")}
                    {...a11yProps(0)}
                    className="api-tabs"
                    sx={{ textTransform: "capitalize", minWidth: "200px" }}
                    // width: "170px"
                  />
                  <Tab
                    sx={{ textTransform: "capitalize", minWidth: "200px" }}
                    // width: "170px"
                    label={t("WEBHOOK_SETTINGS")}
                    {...a11yProps(1)}
                    className="webhook-tabs"
                  />
                  <Tab
                    sx={{ textTransform: "capitalize", minWidth: "200px" }}
                    // width: "170px"
                    label={t("GOOGLE_PAY_SETTINGS")}
                    {...a11yProps(2)}
                    className="google-tabs"
                  />
                  <Tab
                    sx={{ textTransform: "capitalize", minWidth: "200px" }}
                    // width: "200px"
                    label={t("CHECKOUT_CONFIG_SETTINGS")}
                    {...a11yProps(3)}
                    className="google-tabs"
                  />
                </Tabs>
              </Box>

              <CustomTabPanel value={value} index={0}>
                <Box>
                  <Typography className="credential-text">
                    {t("API_CREDENTIALS")}
                  </Typography>
                  <Box>
                    <ApiCredentials />
                  </Box>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Box>
                  <Webhooks />
                </Box>
              </CustomTabPanel>
            </Box>
            <CustomTabPanel value={value} index={2}>
              <Box>
                <Box>
                  <GooglePaySettings />
                </Box>
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <Box className="checkout-setting-container">
                <Box className="checkout-main-container">
                  <CheckoutConfiguration />
                </Box>
              </Box>
            </CustomTabPanel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Developers;
