import React, { useEffect, useLayoutEffect, useState } from "react";
import { SketchPicker } from "react-color"; // Importing the SketchPicker from react-color
import "./CheckoutConfiguration.css"; // Import the CSS file
import { Dropdown } from "@mui/base/Dropdown";
import { Box, Chip, Grid, Typography } from "@mui/material";
import { MenuButton as BaseMenuButton } from "@mui/base/MenuButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CssTransition } from "@mui/base/Transitions";
import { MenuItem as BaseMenuItem, menuItemClasses } from "@mui/base/MenuItem";
import { styled } from "@mui/material/styles";
import { PopupContext } from "@mui/base/Unstable_Popup";
import { Menu } from "@mui/base/Menu";
import { Circle } from "@mui/icons-material";
import PaymentPreference from "../PaymentPreference/PaymentPreference";
import { httpClient } from "utils/HttpClient";
import { URL, CHECKOUT_SETTINGS_ENDPOINT } from "constants/Constants";

const fontWeights = ["Light", "Regular", "Medium", "Bold", "ExtraBold"];

const CheckoutConfiguration = () => {
  const [config, setConfig] = useState({});
  //   primaryColor: "#FF6347",
  //   secondaryColor: "#32CD32",
  //   primaryFontColor: "#FFFFFF",
  //   secondaryFontColor: "#000000",
  //   fontWeight: "Regular",
  //   fontSize: 16,
  //   title: "Checkout Title",
  //   logoUrl: "",
  //   paymentPreference: [],
  // });

  const [showColorPicker, setShowColorPicker] = useState(false);
  const [activeColor, setActiveColor] = useState("primaryColor");

  useLayoutEffect(() => {
    fetchConfigurationData();
  }, []);
  const fetchConfigurationData = async () => {
    try {
      const data = await httpClient.fetchData(
        `${URL}${CHECKOUT_SETTINGS_ENDPOINT}`,
        "GET"
      );
      setConfig(data);
      console.log(data, "Config data");
    } catch (error) {
      console.error("Error fetching Configuration data:", error);
    }
  };

  const saveConfigurationSettings = () => {
    console.log(config, "Config data");
    httpClient
      .fetchData(`${URL}${CHECKOUT_SETTINGS_ENDPOINT}`, "POST", config)
      .then((response) => {
        fetchConfigurationData();
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConfig((prevConfig) => ({
      ...prevConfig,
      [name]: value,
    }));
  };

  const handleFontSizeChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value)) {
      setConfig((prevConfig) => ({
        ...prevConfig,
        fontSize: value,
      }));
    }
  };

  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };

  const blue = {
    50: "#F0F7FF",
    100: "#C2E0FF",
    200: "#99CCF3",
    300: "#66B2FF",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E6",
    700: "#0059B3",
    800: "#004C99",
    900: "#003A75",
  };

  const MenuItem = styled(BaseMenuItem)(
    ({ theme }) => `
      list-style: none;
      padding: 8px;
      border-radius: 8px;
      cursor: default;
      user-select: none;
    
      &:last-of-type {
        border-bottom: none;
      }
    
      &:focus {
        outline: 3px solid ${
          theme.palette.mode === "dark" ? blue[600] : blue[200]
        };
        background-color: ${
          theme.palette.mode === "dark" ? grey[800] : grey[100]
        };
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      }
    
      &.${menuItemClasses.disabled} {
        color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
      }
      `
  );

  const MenuButton = styled(BaseMenuButton)(
    ({ theme }) => `
      display: flex;
      padding: 4px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      border: 0;
      background-color: white;
      `
  );

  // Handle color selection from color picker
  const handleColorChange = (color) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      [activeColor]: color.hex,
    }));
    setShowColorPicker(!showColorPicker);
  };

  // Toggle the visibility of the color picker
  const toggleColorPicker = (colorType) => {
    setActiveColor(colorType);
    setShowColorPicker(!showColorPicker);
  };

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setConfig((prevConfig) => ({
  //       ...prevConfig,
  //       logoUrl: URL.createObjectURL(file),
  //     }));
  //   }
  // };

  const handleImageChange = (event) => {
    const url = event.target.value;
    if (url) {
      setConfig((prevConfig) => ({
        ...prevConfig,
        logoUrl: url,
      }));
    }
  };

  const handleDataFromChild = (data) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      paymentPreference: data,
    }));
  };

  const Listbox = styled("ul")(
    ({ theme }) => `
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 0.875rem;
      box-sizing: border-box;
      padding: 6px;
      margin: 12px 0;
      min-width: 200px;
      border-radius: 12px;
      overflow: auto;
      outline: 0px;
      background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
      border: 1px solid ${
        theme.palette.mode === "dark" ? grey[700] : grey[200]
      };
      color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
      box-shadow: 0px 4px 30px ${
        theme.palette.mode === "dark" ? grey[900] : grey[200]
      };
      z-index: 1;
    
      .closed & {
        opacity: 0;
        transform: scale(0.95, 0.8);
        transition: opacity 200ms ease-in, transform 200ms ease-in;
      }
      
      .open & {
        opacity: 1;
        transform: scale(1, 1);
        transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
      }
    
      .placement-top & {
        transform-origin: bottom;
      }
    
      .placement-bottom & {
        transform-origin: top;
      }
      `
  );

  const AnimatedListbox = React.forwardRef(function AnimatedListbox(
    props,
    ref
  ) {
    const { ownerState, ...other } = props;
    const popupContext = React.useContext(PopupContext);

    if (popupContext == null) {
      throw new Error(
        "The `AnimatedListbox` component cannot be rendered outside a `Popup` component"
      );
    }

    const verticalPlacement = popupContext.placement.split("-")[0];

    return (
      <CssTransition
        className={`placement-${verticalPlacement}`}
        enterClassName="open"
        exitClassName="closed"
      >
        <Listbox {...other} ref={ref} />
      </CssTransition>
    );
  });

  useEffect(() => {
    // Load PayorchCheckout script
    const script = document.createElement("script");
    script.src = "https://checkout.pr-dev.payorch.com/checkout.js";
    script.async = true;

    script.onload = function () {
      if (window.PayorchCheckout) {
        console.log("PayorchCheckout loaded");
        previewCheckout();
      } else {
        console.error("PayorchCheckout is not defined");
      }
    };

    script.onerror = function () {
      console.error("Failed to load checkout.js script");
    };

    document.body.appendChild(script);

    // Clean up script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const previewCheckout = () => {
    const dataObj = {
      autoCapture: true,
      type: "PAYMENT",
      merchantReferenceId: Date.now(),
      createCustomer: true,
      savePaymentInstrument: true,
      order: {
        lineItems: [
          {
            itemId: "1",
            name: "Polo Shirt",
            sku: "sku-1",
            brand: "Samsung",
            type: "goods",
            url: "http://localhost:3000/images/shirt.png",
            quantity: 1,
            category: "services",
            amount: {
              currencyCode: "IDR",
              value: 10000,
            },
          },
        ],
      },
      amount: {
        currencyCode: "IDR",
        value: 10000,
      },
      descriptor: "Test tran",
      description: "Test tran",
      customer: {
        merchantCustomerId: "user1",
        firstName: "John",
        lastName: "Deo",
        email: "john.deo@gmail.com",
        nationality: "ID",
        phone: {
          countryCode: "+61",
          number: "5454466465",
        },
      },
      billingAddress: {
        name: "Home",
        line1: "Pert Selmis Bl I/52",
        line2: "",
        city: "Jakarta",
        state: "Jakarta",
        country: "ID",
        zip: "12830",
      },
    };

    var url = "https://api.pr-dev.payorch.com/payorch/api/v1/checkoutsessions";

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic bWVyY2hhbnRkZW1vMTpUZXN0QDEyMw==",
      },
      body: JSON.stringify(dataObj),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("HTTP error, status = " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        const checkout = new window.PayorchCheckout();
        setTimeout(() => {
          const checkoutParentDiv =
            document.getElementsByClassName("payorch")[0];
          const backdropDivToBeRemoved =
            document.getElementsByClassName("modal-overlay")[0];
          const modalDivToBeAdded =
            document.getElementsByClassName("modal-content")[0];
          checkoutParentDiv.style.width = "50%";
          checkoutParentDiv.style.height = "fit-content";
          modalDivToBeAdded.style.width = "100%";
          if (checkoutParentDiv.contains(backdropDivToBeRemoved)) {
            checkoutParentDiv.removeChild(backdropDivToBeRemoved);
          }
          checkoutParentDiv.appendChild(modalDivToBeAdded);
          const portalParentDiv = document.getElementsByClassName(
            "checkout-setting-container"
          )[0];
          portalParentDiv.appendChild(checkoutParentDiv);
          document.getElementsByClassName("modal-body")[0].style.maxHeight =
            "100%";
          checkoutParentDiv.style.border = "1px solid #cacfd8";
          checkoutParentDiv.style.borderRadius = "26px";
          document.getElementsByClassName(
            "modal-footer-action-container"
          )[0].style.padding = "24px";
        }, 1000);

        checkout.initialize(
          {
            apiKey: "bWVyY2hhbnRkZW1vMTpUZXN0QDEyMw==",
            checkoutSessionToken: data.token,
            scroll: true,
          },
          function (result) {
            console.log(result);
            var searchUrl = `https://api.pr-test.payorch.com/payorch/api/v1/search`;
            const payload = { checkoutSessionToken: data.token };
            fetch(searchUrl, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Basic bWVyY2hhbnRkZW1vMTpUZXN0QDEyMw==",
              },
              body: JSON.stringify(payload),
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error("HTTP error, status = " + response.status);
                }
                return response.json();
              })
              .then((data) => {});

            // window.location.reload();
          }
        );
      });
  };

  return (
    <div className="main-container">
      <Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography className="config-page-text">
            Checkout Configuration
          </Typography>
        </Box>
        <Box className="hr-line-label-container">
          <div className="hr-line"></div>
        </Box>
        <Grid item xs={12} className="customization-grid">
          <Box>
            <div className="section">
              <div>
                <label htmlFor="fontSize" className="checkout-text">
                  Checkout Title:
                </label>
              </div>
              <div className="input-50">
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={config.title}
                  onChange={handleChange}
                  min="10"
                  max="50"
                />
              </div>
            </div>
            <div className="section">
              <div>
                <label htmlFor="fontSize" className="checkout-text">
                  Logo URL:
                </label>
              </div>
              <div className="input-50">
                <input
                  type="url"
                  id="imageUpload"
                  name="Logo"
                  placeholder="Logo URL"
                  value={config.logoUrl}
                  onChange={handleImageChange}
                  min="10"
                  max="50"
                />
              </div>
            </div>
            {/* <div className="section">
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <input
                  type="file"
                  id="imageUpload"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
                ̰
              </div>
            </div> */}
          </Box>
        </Grid>
        <Grid item xs={12} className="customization-grid">
          <Box>
            <div className="section">
              <div>
                <label htmlFor="primaryColor" className="checkout-text">
                  Primary Color:
                </label>
              </div>
              <div>
                <div
                  className="color-picker-swatch"
                  style={{ backgroundColor: config.primaryColor }}
                  onClick={() => toggleColorPicker("primaryColor")}
                />
                {showColorPicker && activeColor === "primaryColor" && (
                  <div className="color-picker-popup">
                    <SketchPicker
                      color={config.primaryColor}
                      onChange={handleColorChange}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="section">
              <div>
                <label htmlFor="secondaryColor" className="checkout-text">
                  Secondary Color:
                </label>
              </div>
              <div>
                <div
                  className="color-picker-swatch"
                  style={{ backgroundColor: config.secondaryColor }}
                  onClick={() => toggleColorPicker("secondaryColor")}
                />
                {showColorPicker && activeColor === "secondaryColor" && (
                  <div className="color-picker-popup">
                    <SketchPicker
                      color={config.secondaryColor}
                      onChange={handleColorChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} className="customization-grid">
        <Box>
          <div className="section">
            <div>
              <label className="checkout-text" htmlFor="primaryFontColor">
                Primary Font Color:
              </label>
            </div>
            <div>
              <div
                className="color-picker-swatch"
                style={{ backgroundColor: config.primaryFontColor }}
                onClick={() => toggleColorPicker("primaryFontColor")}
              />
              {showColorPicker && activeColor === "primaryFontColor" && (
                <div className="color-picker-popup">
                  <SketchPicker
                    color={config.primaryFontColor}
                    onChange={handleColorChange}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="section">
            <div>
              <label className="checkout-text" htmlFor="secondaryFontColor">
                Secondary Font Color:
              </label>
            </div>
            <div>
              <div
                className="color-picker-swatch"
                style={{ backgroundColor: config.secondaryFontColor }}
                onClick={() => toggleColorPicker("secondaryFontColor")}
              />
              {showColorPicker && activeColor === "secondaryFontColor" && (
                <div className="color-picker-popup">
                  <SketchPicker
                    color={config.secondaryFontColor}
                    onChange={handleColorChange}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="section">
            <div>
              <label className="checkout-text" htmlFor="secondaryFontColor">
                Font Weight:
              </label>
            </div>
            <div>
              <div>
                <Dropdown>
                  <MenuButton
                    sx={{ paddingBottom: "20px", marginRight: "4px" }}
                  >
                    <Chip
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            className="settings-title"
                            sx={{ fontSize: "13px", marginTop: "2px" }}
                          >
                            {config.fontWeight}{" "}
                          </Typography>
                          <KeyboardArrowDownIcon className="settings-title" />
                        </Box>
                      }
                    />
                  </MenuButton>
                  <Menu slots={{ listbox: AnimatedListbox }}>
                    <Typography className="menu-header">
                      Select font weight
                    </Typography>
                    {fontWeights.map((value) => (
                      <MenuItem
                        // disabled={checkIfCurrencyExists(currency.code)}
                        onClick={() =>
                          handleChange({
                            target: { name: "fontWeight", value: value },
                          })
                        }
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Circle className="dot-circle" />
                        <Typography className="currency-name">
                          {value}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Dropdown>
              </div>
            </div>
          </div>

          <div className="section">
            <div>
              <label className="checkout-text" htmlFor="fontSize">
                Font Size:
              </label>
            </div>
            <div className="input-50">
              <input
                type="number"
                id="fontSize"
                name="fontSize"
                value={config.fontSize}
                onChange={handleFontSizeChange}
                min="10"
                max="50"
              />
            </div>
          </div>

          <div className="section">
            <div>
              <label className="checkout-text" htmlFor="fontSize">
                Arrange Payment methods
              </label>
            </div>
            <div>
              <PaymentPreference onDataFromChild={handleDataFromChild} />
            </div>
          </div>
          <div className="section" style={{ justifyContent: "end" }}>
            <button
              className="color-picker-button btn btn-primary"
              onClick={() => saveConfigurationSettings()}
            >
              Save Settings
            </button>
            {/* <button
              className="color-picker-button btn btn-primary pl-5"
              onClick={() => previewCheckout()}
            >
              Preview Checkout
            </button> */}
          </div>
        </Box>
      </Grid>
    </div>
  );
};

export default CheckoutConfiguration;
